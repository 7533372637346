import React from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { CommunitySection } from '../../modules/common/components/community-section';
import { Accordion, Button, Card, Col, Row } from 'react-bootstrap';

import './index.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { CategoryCard } from '../../modules/cs/main/components/category-card';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import Countdown from 'react-countdown';
import { Link } from 'gatsby';
import { AshTrace } from '../../modules/ash/components/ash-trace';
import { AshCharacter } from '../../modules/ash/components/ash-character';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AshIndexPage: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderer1 = ({ days, hours, minutes, completed }) => {
    if (completed) {
      return <span className="time">Event ended</span>;
    } else {
      return (
        <span className="time">
          {days >= 1 ? (
            <>
              {days}d {hours}h
            </>
          ) : (
            <>
              {hours}h {minutes}m
            </>
          )}
        </span>
      );
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderer2 = ({ days, hours, minutes, completed }) => {
    if (completed) {
      return <span className="time">Event started</span>;
    } else {
      return (
        <span className="time">
          {days >= 1 ? (
            <>
              {days}d {hours}h
            </>
          ) : (
            <>
              {hours}h {minutes}m
            </>
          )}
        </span>
      );
    }
  };
  return (
    <DashboardLayout className={'home-page ash '} game="ash">
      <div className="dashboard-hero">
        <h1>Prydwen.gg - Ash Echoes wiki and tier list</h1>
        <h2>
          Prydwen.gg is a wiki for Ash Echoes Global - a gacha game published by{' '}
          <OutboundLink href="https://ae.neocraftstudio.com/en" target="_blank">
            Neocraft
          </OutboundLink>
          . Check our guides, tier lists and reviews for characters available in
          the game.
        </h2>
        <p>For the official Ash Echoes Global website go here:</p>
        <div className="centered-links">
          <div>
            <OutboundLink
              href="https://ae.neocraftstudio.com/en"
              target="_blank"
            >
              <StaticImage
                src="../../images/ash/neocraft_logo.webp"
                alt="A-Level"
                className="partner-logo"
              />
            </OutboundLink>
          </div>
          <div>
            <OutboundLink
              href="https://ae.neocraftstudio.com/download"
              target="_blank"
            >
              <Button variant="white">
                <FontAwesomeIcon icon={faDownload} width="18" /> Download Ash
                Echoes
              </Button>
            </OutboundLink>
          </div>
        </div>
        <p className="with-margin-top">
          <strong>The links above don't work for you?</strong> If you can't
          access the links posted above, you can directly download Ash Echoes
          for your mobile phone{' '}
          <a
            href="https://accounts.neocraftstudio.com/download?op_id=2117&game_id=199"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{' '}
          (via APK) and the official PC client from{' '}
          <a
            href="https://static.neocraftstudio.com/static/download/AE/seed_setup_us_pro_1.5.7.exe"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <SectionHeader title="Important Content" />
      <div className="promoted-content">
        <div className="banner-promo event-3">
          <h4>Tomorrow is a Blooming Day Event Guide</h4>
          <Link to="/ash-echoes/guides/tomorrow-is-a-blooming-day">
            <Button variant="white">Read more</Button>
          </Link>
        </div>
        <div className="banner-promo event-1">
          <h4>Boss Challenge: Clash of Fronts Guide</h4>
          <Link to="/ash-echoes/guides/boss-challenge-clash-of-floats">
            <Button variant="white">Read more</Button>
          </Link>
        </div>
        <div className="banner-promo scar">
          <h4>Should you pull Scarlett?</h4>
          <Link to="/ash-echoes/guides/should-you-pull-scarlett">
            <Button variant="white">Read more</Button>
          </Link>
        </div>
        <div className="banner-promo bali">
          <h4>Should you pull Baili Tusu?</h4>
          <Link to="/ash-echoes/guides/should-you-pull-baili-tusu">
            <Button variant="white">Read more</Button>
          </Link>
        </div>
      </div>
      <SectionHeader title={'Event Timeline'} />
      <Row xs={1} xl={2}>
        <Col>
          <div className="tracker-header">
            <h5>Current</h5>
          </div>
          <Accordion className="event-tracker">
            <Accordion.Item className="banner-4" eventKey="1012">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Standard Resonance (Banner)</div>
                <div className="countdown">
                  <Countdown date={1733994000000} renderer={renderer1} />
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: November 21, 04:00 - December 12,
                  03:59 AM (UTC-5)
                </p>
                <p className="featured">
                  Featured <span className="ash-rar rarity-6">6★</span>{' '}
                  character:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <AshCharacter
                      slug="luke"
                      mode="card"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="banner-5" eventKey="1014">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Scarlet Skies (Banner)</div>
                <div className="countdown">
                  <Countdown date={1733994000000} renderer={renderer1} />
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: November 28, 2024, 04:00 –
                  December 12, 2024, 03:59 (UTC-5)
                </p>
                <p className="featured">
                  Featured <span className="ash-rar rarity-6">6★</span>{' '}
                  character:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <AshCharacter
                      slug="scarlett"
                      mode="card"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
                <p className="featured">
                  Featured <span className="ash-rar rarity-5">5★</span>{' '}
                  characters:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <AshCharacter
                      slug="shiratori-azusa"
                      mode="card"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <AshCharacter
                      slug="xiangling"
                      mode="card"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <AshCharacter
                      slug="felicio"
                      mode="card"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="banner-51" eventKey="1016">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Going Off-Course (Banner)</div>
                <div className="countdown">
                  <Countdown date={1733994000000} renderer={renderer1} />
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: November 28, 2024, 04:00 –
                  December 12, 2024, 03:59 (UTC-5)
                </p>
                <p className="featured">
                  Featured <span className="ash-rar rarity-SSR">SSR</span>{' '}
                  Memory Trace:
                </p>
                <div className="ash-trace-box featured-item">
                  <AshTrace name="Going Off-Course" mode="data" />
                </div>
                <p className="featured">
                  Featured <span className="ash-rar rarity-SR">SR</span> Memory
                  Traces:
                </p>
                <div className="ash-trace-box featured-item">
                  <AshTrace name="Reunion" mode="data" />
                </div>
                <div className="ash-trace-box featured-item">
                  <AshTrace name="Lawful Cage" mode="data" />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="banner-7" eventKey="1013">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Standard Resonance (Banner)</div>
                <div className="countdown">
                  <Countdown date={1734598800000} renderer={renderer1} />
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: December 5, 04:00 - December 19,
                  03:59 AM (UTC-5)
                </p>
                <p className="featured">
                  Featured <span className="ash-rar rarity-6">6★</span>{' '}
                  character:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <AshCharacter
                      slug="mo-hongxiu"
                      mode="card"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="project" eventKey="1030">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Project Vein (Battle Pass)</div>
                <div className="countdown">
                  <Countdown date={1734944400000} renderer={renderer1} />
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: November 13, 2024, 16:00 -
                  December 23, 2024, 4:00 (UTC-5).
                </p>
                <p className="description">
                  <span>Event description</span>: During this event, Directors
                  can level up Project Vein Lv. by completing tasks in PROJECT
                  VEIN to gain abundant rewards. Unlock [Advance Plan] to level
                  up your Project Vein Lv. by 10, get the limited SR Memory
                  Trace [Secret Melody], Tea Time Universal Invitations, and
                  advanced supplies!
                </p>
                <p className="featured">
                  Featured <span className="ash-rar rarity-SR">SR</span> Memory
                  Trace reward:
                </p>
                <div className="ash-trace-box featured-item">
                  <AshTrace name="Secret Melody" mode="data" />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="event-4" eventKey="1023">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Tomorrow is a Blooming Day</div>
                <div className="countdown">
                  <Countdown date={1735203600000} renderer={renderer1} />
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: November 28, 2024, 04:00 –
                  December 26, 2024, 03:59 (UTC-5)
                </p>
                <p className="description">
                  <span>Event description</span>: Tomorrow is a Blooming Day
                  Event stages will open for a limited time. Players can obtain
                  event rewards by clearing stages and completing the event
                  quests, or redeem them from Event Store.
                </p>
                <p className="description">
                  <span>Rewards</span>: [SSR - Memory Traces: Festive Night],
                  Transcript: Festive Night, Resonance Clue, Momentary
                  Backflash, Event Commemorative Furniture, etc. (Echomancer
                  Scarlett can have special interaction with the furniture
                  [Scarlett's Wind Chime]; Echomancer Baili Tusu can have
                  special interaction with the furniture [Baili Tusu's Mask].)
                </p>
                <p className="featured">
                  Featured <span className="ash-rar rarity-SSR">SSR</span>{' '}
                  Memory Trace reward:
                </p>
                <div className="ash-trace-box featured-item">
                  <AshTrace name="Festive Night" mode="data" />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="event-5" eventKey="1024">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Float Parade</div>
                <div className="countdown">
                  <Countdown date={1735203600000} renderer={renderer1} />
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: November 28, 2024, 04:00 –
                  December 26, 2024, 03:59 (UTC-5)
                </p>
                <p className="description">
                  <span>Event description</span>: The new gameplay Float Parade
                  is coming soon! Drive the Float to overcome obstacles along
                  the way, and enjoy endless surprises. Obtain event rewards by
                  completing the event missions!
                </p>
                <p className="description">
                  <span>Rewards</span>: Frostfare Coins, X Prticle, etc.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="event-6" eventKey="1025">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">
                  Boss Challenge: Clash of Fronts
                </div>
                <div className="countdown">
                  <Countdown date={1735203600000} renderer={renderer1} />
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: November 30, 2024, 04:00 –
                  December 26, 2024, 03:59 (UTC-5)
                </p>
                <p className="description">
                  <span>Event description</span>: Boss Challenge mode will be
                  available during the time. Directors can select the difficulty
                  level of the boss, and get scores based on the challenge
                  results. Reach specified scores to complete quests and win
                  rewards!
                </p>
                <p className="description">
                  <span>Rewards</span>: O.E. Coins, Frostfare Coins, etc.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        <Col>
          <div className="tracker-header">
            <h5>Upcoming</h5>
          </div>
          <Accordion className="event-tracker">
            <Accordion.Item className="event-7" eventKey="1026">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Festive Morning</div>
                <div className="countdown">
                  <Countdown date={1733994000000} renderer={renderer2} />
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: December 12, 2024, 04:00 –
                  December 26, 2024, 03:59 (UTC-5)
                </p>
                <p className="description">
                  <span>Event description</span>: During the event, completing
                  Echoing Nexus each time rewards a certain number of Power
                  Drumbeats. Upon reaching specific milestones, you can claim
                  generous rewards on the corresponding event page at the Event
                  Center.
                </p>
                <p className="description">
                  <span>Rewards</span>: [SR - Memory Traces: Festive Morning],
                  Transcript: Festive Morning, Blacklight, X Particle, Gilded
                  Onigiri, O.E. Coins, Mirrorshard, etc.
                </p>
                <p className="featured">
                  Featured <span className="ash-rar rarity-SR">SR</span> Memory
                  Trace reward:
                </p>
                <div className="ash-trace-box featured-item">
                  <AshTrace name="Festive Morning" mode="data" />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="banner-6" eventKey="1015">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Light Rekindled (Banner)</div>
                <div className="countdown">
                  <Countdown date={1733994000000} renderer={renderer2} />
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: December 12, 2024, 04:00 –
                  December 26, 2024, 03:59 (UTC-5)
                </p>
                <p className="featured">
                  Featured <span className="ash-rar rarity-6">6★</span>{' '}
                  character:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <AshCharacter
                      slug="baili-tusu"
                      mode="card"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
                <p className="featured">
                  Featured <span className="ash-rar rarity-5">5★</span>{' '}
                  characters:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <AshCharacter
                      slug="roar"
                      mode="card"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <AshCharacter
                      slug="reid"
                      mode="card"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <AshCharacter
                      slug="bellia"
                      mode="card"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            {/* <Accordion.Item className="banner-61" eventKey="1017">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Going Off-Course (Banner)</div>
                <div className="countdown">
                  <Countdown date={1733994000000} renderer={renderer2} />
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: December 12, 2024, 04:00 –
                  December 26, 2024, 03:59 (UTC-5)
                </p>
                <p className="featured">
                  Featured <span className="ash-rar rarity-SSR">SSR</span>{' '}
                  Memory Trace:
                </p>
                <div className="ash-trace-box featured-item">
                  <AshTrace name="Going Off-Course" mode="data" />
                </div>
                <p className="featured">
                  Featured <span className="ash-rar rarity-SR">SR</span> Memory
                  Traces:
                </p>
                <div className="ash-trace-box featured-item">
                  <AshTrace name="Reunion" mode="data" />
                </div>
                <div className="ash-trace-box featured-item">
                  <AshTrace name="Lawful Cage" mode="data" />
                </div>
              </Accordion.Body>
            </Accordion.Item> */}
          </Accordion>
        </Col>
      </Row>
      <SectionHeader title="Guides" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Introduction to the game"
          link="/ash-echoes/guides/introduction-to-the-game"
          image={
            <StaticImage
              src="../../images/ash/categories/category_intro.webp"
              alt="Introduction to the game"
            />
          }
        />
        <CategoryCard
          title="Beginner Guide"
          link="/ash-echoes/guides/beginner-guide"
          image={
            <StaticImage
              src="../../images/ash/categories/category_beginner.webp"
              alt="Beginner Guide"
            />
          }
        />
        <CategoryCard
          title="Reroll Guide"
          link="/ash-echoes/guides/reroll"
          image={
            <StaticImage
              src="../../images/ash/categories/category_reroll.webp"
              alt="Reroll Guide"
            />
          }
        />
        <CategoryCard
          title="Elements & Reactions"
          link="/ash-echoes/guides/elements-and-reactions"
          image={
            <StaticImage
              src="../../images/ash/categories/category_elements.webp"
              alt="Elements & Reactions"
            />
          }
        />
        <CategoryCard
          title="Team Building"
          link="/ash-echoes/guides/team-building"
          image={
            <StaticImage
              src="../../images/ash/categories/category_teams.webp"
              alt="Team Building"
            />
          }
        />
        <CategoryCard
          title="Characters"
          link="/ash-echoes/characters"
          image={
            <StaticImage
              src="../../images/ash/categories/category_characters.webp"
              alt="Characters"
            />
          }
        />
        <CategoryCard
          title="Tier List"
          link="/ash-echoes/tier-list"
          image={
            <StaticImage
              src="../../images/ash/categories/category_tier.webp"
              alt="Tier List"
            />
          }
        />
        <CategoryCard
          title="Tea Time Drinks"
          link="/ash-echoes/tea-time"
          image={
            <StaticImage
              src="../../images/ash/categories/category_tea.webp"
              alt="Tea Time Drinks"
            />
          }
        />
        <CategoryCard
          title="Game Modes"
          link="/ash-echoes/guides/game-modes"
          image={
            <StaticImage
              src="../../images/ash/categories/category_modes.webp"
              alt="Game Modes"
            />
          }
        />
        <CategoryCard
          title="Echoing Nexus Overview"
          link="/ash-echoes/guides/echoing-nexus"
          image={
            <StaticImage
              src="../../images/ash/categories/category_nexus.webp"
              alt="Echoing Nexus Overview"
            />
          }
        />
        <CategoryCard
          title="Memory Traces"
          link="/ash-echoes/memory-traces"
          image={
            <StaticImage
              src="../../images/ash/categories/category_traces.webp"
              alt="Memory Traces"
            />
          }
        />
      </Row>
      <SectionHeader title="Community" />
      <CommunitySection />
    </DashboardLayout>
  );
};

export default AshIndexPage;

export const Head: React.FC = () => (
  <Seo
    title="Ash Echoes Wiki & Database | Prydwen Institute"
    description="Prydwen.gg is a wiki for Ash Echoes. Check our guides, tier lists and reviews for characters available in the game."
    game="ash"
  />
);
